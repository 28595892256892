<template>
  <v-card>
    <v-file-input
      v-model="file"
      id="document_file_sharing"
      @change="uploadFile"
      class="d-none"
    />
    <v-card-title>
      {{ $t("APPLICATION.FILE_SHARING.TITLE") }}
    </v-card-title>
    <v-card-text class="contentCardFile">
      <!-- HEADER TABS -->
      <v-tabs v-model="tab">
        <v-tab>
          {{ $t("APPLICATION.FILE_SHARING.TITLE_TAB1") }}
        </v-tab>
        <v-tab>
          {{ $t("APPLICATION.FILE_SHARING.TITLE_TAB2") }}
        </v-tab>
      </v-tabs>
      <!-- CONTENT TABS -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div
                v-for="(file, index) in student_files"
                :key="index"
                class="divider"
              >
                <strong class="font-size-1-2">
                  {{ file.name }}
                </strong>
                <span class="float-right font-size-1-2">
                  <a :href="file.url" target="_blank">
                    <font-awesome-icon icon="eye" />
                  </a>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div
                v-for="(file, index) in provider_files"
                :key="index"
                class="divider"
              >
                <strong class="font-size-1-2">
                  {{ file.name }}
                </strong>
                <span class="float-right font-size-1-2">
                  <a :href="file.url" target="_blank">
                    <font-awesome-icon icon="eye" />
                  </a>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-btn
        depressed
        color="primary"
        :loading="upload_loading"
        @click="uploadFileClick"
        class="ml-2"
      >
        <font-awesome-icon icon="upload" class="mr-1" />
        {{ $t("APPLICATION.UPLOAD") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { UPLOAD_FILE } from "@/core/services/store/application/application.module";

const PROVIDER = 7;
const STUDENT = 8;

export default {
  data() {
    return {
      tab: null,
      upload_loading: false,
      file: null,
      provider_files: [],
      student_files: [],
      listFiles: false,
    };
  },
  props: {
    documentData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    /**
     * get new items
     * @method uploadFileClick
     */
    uploadFileClick() {
      const input = document.getElementById("document_file_sharing");
      input.click();
    },

    /**
     * get new items
     * @method typeFile
     */
    typeFile(documentType, file) {
      if (documentType === STUDENT) {
        this.student_files.push(file);
      } else if (documentType === PROVIDER) {
        this.provider_files.push(file);
      }
    },

    /**
     * get new items
     * @method uploadFile
     */
    uploadFile() {
      this.upload_loading = true;

      const {
        applicationProductId,
        campusId,
      } = this.application.ApplicationProducts[0];

      let formData = new FormData();
      formData.append("files", this.file);
      formData.append("documentTypeId", PROVIDER);
      formData.append("campusId", campusId);
      formData.append("applicationProductId", applicationProductId);
      formData.append("from", this.currentUser.name);
      formData.append("studentId", this.application.studentId);

      this.$store
        .dispatch(UPLOAD_FILE, formData)
        .then(data => {
          const file = data[data.length - 1].File;

          this.typeFile(PROVIDER, file);

          this.$bvToast.toast(this.$t("GENERAL.SAVE_FILE"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });

          this.upload_loading = false;
          this.file = null;
        })
        .catch(() => {
          this.upload_loading = false;
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.FILE_NEW"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
        });
    },

    verifyFilesUploaded() {
      this.documentData.forEach(element => {
        const { File } = element;
        const { typeId } = File;
        this.typeFile(typeId, File);
      });
    },
  },
  computed: {
    ...mapGetters(["getCampus", "currentUser", "application"]),
  },
  watch: {
    documentData: function() {
      this.verifyFilesUploaded();
    },
  },
};
</script>

<style scoped>
.contentCardFile {
  min-height: 230px;
}

.font-size-1-2 {
  font-size: 1.1rem;
}

.divider {
  border-bottom: 1px solid #b9b4b4;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
