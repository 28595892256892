<template>
  <v-card>
    <v-card-title>
      {{ $t("APPLICATION.PAYMENT.TITLE") }}
    </v-card-title>
    <!-- CHANGE -->
    <v-card-text>
      <v-row>
        <!-- COLUNM 1 -->
        <b-col lg="4" md="4" sm="4" cols="6" class="font-size">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.PAYMENT.TOTAL_AMOUNT_PRODUCTS") }} :
          </strong>
        </b-col>
        <b-col lg="8" md="8" sm="8" cols="6" class="font-size">
          {{ application.totalProductCurrency }} ({{
            application.totalProductCurrencyEUR
          }})
        </b-col>
        <!-- COLUNM 2 -->
        <b-col lg="4" md="4" sm="4" cols="6" class="font-size">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.PAYMENT.TOTAL_AMOUNT_INSURANCE") }} :
          </strong>
        </b-col>
        <b-col lg="8" md="8" sm="8" cols="6" class="font-size">
          {{ application.totalAddonCurrency }}
        </b-col>
        <!-- COLUNM 3 -->
        <b-col lg="4" md="4" sm="4" cols="6" class="font-size">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.PAYMENT.TOTAL_REMAINING_PAYMENTS") }} :
          </strong>
        </b-col>
        <b-col lg="8" md="8" sm="8" cols="6" class="font-size">
          {{ application.totalpayment }} ({{ application.totalpaymentEUR }})
        </b-col>
      </v-row>
      <v-row>
        <b-col lg="12" md="12" sm="12" cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("APPLICATION.PAYMENT.TRANSACTION_ID") }}
                  </th>
                  <th class="text-left">
                    {{ $t("APPLICATION.PAYMENT.PAYMENT_METHOD") }}
                  </th>
                  <th class="text-left">
                    {{ $t("APPLICATION.PAYMENT.EQUIVALENT_AMOUNT") }}
                  </th>
                  <th class="text-left">
                    {{ $t("APPLICATION.PAYMENT.AMOUNT_PAYED") }}
                  </th>
                  <th class="text-left">
                    {{ $t("APPLICATION.PAYMENT.STATE") }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(payment, index) in application.ApplicationPayments"
                  :key="index"
                >
                  <td>{{ payment.reference }}</td>
                  <td>{{ methodPayment(payment.methodId) }}</td>
                  <td>
                    {{ payment.equivalent }}
                  </td>
                  <td v-if="index === 0">
                    <span v-if="payment.amountProduct > 0">
                      {{
                        `${payment.amountProviderCourse} + ${application.totalAddonCurrency}`
                      }}
                    </span>
                    <span v-else>
                      {{ `${application.totalAddonCurrency}` }}
                    </span>
                  </td>
                  <td v-else>
                    <span v-if="payment.amountProduct > 0">
                      {{ payment.amountProviderCourse }}
                    </span>
                  </td>
                  <td>{{ getStatus(payment.status) }}</td>
                  <td>
                    <v-btn
                      depressed
                      color="primary"
                      v-if="payment.status === PENDING"
                      @click="confirmPayment(payment.applicationPaymentId)"
                    >
                      {{ $t("APPLICATION.PAYMENT.CONFIRM_PAYMENT") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </b-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_STATUS_PAYMENT_ADMIN } from "@/core/services/store/application/application.module";

export default {
  data() {
    return {
      PENDING: 1,
    };
  },
  methods: {
    getStatus(status) {
      let statusInfo;
      switch (status) {
        case 1:
          statusInfo = this.$t("APPLICATION.STATUS.ITEM1");
          break;
        case 2:
          statusInfo = this.$t("APPLICATION.STATUS.ITEM2");
          break;
        case 3:
          statusInfo = this.$t("APPLICATION.STATUS.ITEM3");
          break;
        case 4:
          statusInfo = this.$t("APPLICATION.STATUS.ITEM4");
          break;
        default:
          statusInfo = this.$t("APPLICATION.STATUS.ITEM1");
          break;
      }

      return statusInfo;
    },
    methodPayment(id) {
      const ListMethodPayment = [
        null,
        this.$t("APPLICATION.PAYMENT_METHOD.PAYPAL"),
        this.$t("APPLICATION.PAYMENT_METHOD.CARD"),
        this.$t("APPLICATION.PAYMENT_METHOD.TRANSFER"),
        this.$t("APPLICATION.PAYMENT_METHOD.SEPA"),
      ];
      return id !== null ? ListMethodPayment[id] : null;
    },
    confirmPayment(applicationPaymentId) {
      this.$store
        .dispatch(UPDATE_STATUS_PAYMENT_ADMIN, applicationPaymentId)
        .then(() => {
          this.$bvToast.toast(this.$t("APPLICATION.MESSAGE.SUCCESS"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["application", "Currencies"]),
  },
};
</script>

<style lang="scss" scoped>
.font-size {
  font-size: 1.1rem !important;
}
</style>
