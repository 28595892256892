<template>
  <v-card v-if="data">
    <v-card-title>
      {{ $t("APPLICATION.LODGING.TITLE") }}
      <div class="status-chip">
        <v-chip
          :color="data.status.color"
          :class="{ 'text-white': data.status.color }"
          small
        >
          {{ data.status.text }}
        </v-chip>
      </div>
    </v-card-title>
    <!-- INFORMATION -->
    <v-card-text>
      <v-row>
        <!-- PROVIDER -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.PROVIDER") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6">
          {{ data.Campus.name }}
        </b-col>

        <!-- ROOM_NAME -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.ROOM_NAME") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6">
          {{ data.Product.name }}
        </b-col>

        <!-- CATEGORY -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.CATEGORY") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6"> </b-col>

        <!-- START_DATE -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.START_DATE") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6">
          {{ data.start_date | moment(campus.date_format) }}
        </b-col>

        <!-- END_DATE -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.END_DATE") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6"> </b-col>

        <!-- DURATION -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.DURATION") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6">
          {{ data.qty }}
        </b-col>

        <!-- FEES -->
        <b-col
          lg="6"
          md="6"
          sm="6"
          cols="6"
          @click="isShowDetailFee = !isShowDetailFee"
          class="clicked"
          v-if="data.ApplicationProductFee.length > 0"
        >
          <strong class="d-block font-size-1rem">
            <font-awesome-icon
              icon="chevron-right"
              v-if="!isShowDetailFee"
            ></font-awesome-icon>
            <font-awesome-icon icon="chevron-down" v-else></font-awesome-icon>
            &nbsp;
            {{ $t("APPLICATION.LODGING.FEES") }}
          </strong>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="6"
          cols="6"
          v-if="data.ApplicationProductFee.length > 0"
        >
          {{ data.totalFee }}
        </b-col>
        <v-expand-transition v-if="data.ApplicationProductFee.length > 0">
          <b-col lg="12" md="12" sm="12" cols="12" v-show="isShowDetailFee">
            <v-list dense>
              <v-list-item
                v-for="(product_fee, index) in data.ApplicationProductFee"
                :key="index"
                class="reduce-min-height"
              >
                <v-list-item-content class="mb-0 mt-0 pb-0 pt-0">{{
                  product_fee.name
                }}</v-list-item-content>
                <v-list-item-content
                  class="align-end justify-end mb-0 mt-0 pb-0 pt-0"
                >
                  {{ product_fee.amountCurrency }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </b-col>
        </v-expand-transition>

        <!-- MEALS -->
        <b-col
          lg="6"
          md="6"
          sm="6"
          cols="6"
          @click="isShowDetailMeal = !isShowDetailMeal"
          class="clicked"
          v-if="data.ApplicationProductMeal"
        >
          <strong class="d-block font-size-1rem">
            <font-awesome-icon
              icon="chevron-right"
              v-if="!isShowDetailMeal"
            ></font-awesome-icon>
            <font-awesome-icon icon="chevron-down" v-else></font-awesome-icon>
            &nbsp;
            {{ $t("APPLICATION.LODGING.MEALS") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6" v-if="data.ApplicationProductMeal">
          {{ data.totalMeal }}
        </b-col>
        <v-expand-transition v-if="data.ApplicationProductMeal">
          <b-col lg="12" md="12" sm="12" cols="12" v-show="isShowDetailMeal">
            <v-list dense>
              <v-list-item class="reduce-min-height">
                <v-list-item-content
                  @click="isShowDetailDiet = !isShowDetailDiet"
                  class="clicked pb-0 pt-0"
                >
                  {{ data.ApplicationProductMeal.room_meal.name }}
                </v-list-item-content>
                <v-list-item-content class="align-end justify-end pb-0 pt-0">
                  {{ data.ApplicationProductMeal.amountMealCurrency }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="reduce-min-height"
                v-if="data.ApplicationProductMeal.diet"
              >
                <v-list-item-content
                  @click="isShowDetailDiet = !isShowDetailDiet"
                  class="clicked pb-0 pt-0"
                >
                  {{
                    data.ApplicationProductMeal.diet.name
                  }}</v-list-item-content
                >
                <v-list-item-content class="align-end justify-end pb-0 pt-0">
                  {{ data.ApplicationProductMeal.amountDietCurrency }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </b-col>
        </v-expand-transition>

        <!-- PRICE -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.LODGING.PRICE") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6">
          {{ data.price }}
        </b-col>

        <!-- PROMOTIONS -->
        <b-col
          lg="6"
          md="6"
          sm="6"
          cols="6"
          @click="isShowDetailPromotion = !isShowDetailPromotion"
          class="clicked"
          v-if="data.ApplicationProductPromotion.length > 0"
        >
          <strong class="d-block font-size-1rem">
            <font-awesome-icon
              icon="chevron-right"
              v-if="!isShowDetailPromotion"
            ></font-awesome-icon>
            <font-awesome-icon icon="chevron-down" v-else></font-awesome-icon>
            &nbsp;
            {{ $t("APPLICATION.COURSE.PROMOTION") }}
          </strong>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="6"
          cols="6"
          v-if="data.ApplicationProductPromotion.length > 0"
        >
          - {{ data.totalPromotion }}
        </b-col>
        <v-expand-transition v-if="data.ApplicationProductPromotion.length > 0">
          <b-col
            lg="12"
            md="12"
            sm="12"
            cols="12"
            v-show="isShowDetailPromotion"
          >
            <v-list dense>
              <v-list-item
                v-for="(promotion, index) in data.ApplicationProductPromotion"
                :key="index"
                class="reduce-min-height"
              >
                <v-list-item-content class="mb-0 mt-0 pb-0 pt-0">
                  {{ getPromotionType(promotion.promotionTypeId) }}
                </v-list-item-content>
                <v-list-item-content
                  class="align-end justify-end mb-0 mt-0 pb-0 pt-0"
                >
                  {{ promotion.amountCurrency }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </b-col>
        </v-expand-transition>

        <!-- TOTAL PRICE -->
        <b-col lg="6" md="6" sm="6" cols="6">
          <strong class="d-block font-size-1rem">
            {{ $t("APPLICATION.TOTAL_PRICE") }}
          </strong>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="6">
          {{ data.totalProduct }}
        </b-col>

        <!-- ACTIONS -->
        <b-col lg="12" md="12" sm="12" v-if="data.status.code === 1">
          <v-btn
            depressed
            color="primary"
            @click="updateStatus(accept, data.applicationProductId, 'lodging')"
          >
            {{ $t("APPLICATION.ACCEPT") }}
          </v-btn>
          <v-btn
            depressed
            color="error"
            class="ml-3"
            @click="
              updateStatus(declined, data.applicationProductId, 'lodging')
            "
          >
            {{ $t("APPLICATION.DENY") }}
          </v-btn>
        </b-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    data: Object,
    updateStatus: Function,
  },
  data() {
    return {
      change: false,
      accept: 2,
      declined: 3,
      form: {
        lodging: null,
        room: null,
        date: null,
        qty: null,
      },
      isShowDetailFee: false,
      isShowDetailMeal: false,
      isShowDetailDiet: false,
      isShowDetailPromotion: false,
    };
  },
  methods: {
    onChange() {
      this.change = this.change ? false : true;
    },
    getPromotionType(type) {
      let auxLabel = [
        null,
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE1"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE2"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE3"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE4"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE5"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE6"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE7"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE8"),
        this.$t("APPLICATION.PROMOTION_TYPE.TYPE9"),
      ];

      return auxLabel[type];
    },
  },
  computed: {
    ...mapGetters(["courses", "campus"]),
    info() {
      return this.form;
    },
  },
};
</script>

<style scoped>
.font-size-1rem {
  font-size: 1.1rem;
}

.status-chip {
  display: flex;
  width: 60%;
  justify-content: flex-end;
}

.clicked {
  cursor: pointer;
  cursor: hand;
}

.reduce-min-height {
  min-height: 0px !important;
}
</style>
