<template>
  <v-card>
    <v-card-title>
      {{ $t("APPLICATION.ADDONS.TITLE") }}
    </v-card-title>
    <!-- CHANGE -->
    <v-card-text>
      <v-row>
        <b-col lg="12" md="12" sm="12" cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("APPLICATION.ADDONS.NAME") }}
                  </th>
                  <th class="text-left">
                    {{ $t("APPLICATION.ADDONS.AMOUNT") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(addon, index) in application.ApplicationAddons"
                  :key="index"
                >
                  <td>{{ getAddonTypes(addon.applicationAddonTypeId) }}</td>
                  <td>{{ addon.price }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </b-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    getAddonTypes(id) {
      const labels = [
        null,
        this.$t("APPLICATION.ADDONS_TYPE.INSURANCE_EU"),
        this.$t("APPLICATION.ADDONS_TYPE.INSURANCE_WORLD"),
        this.$t("APPLICATION.ADDONS_TYPE.CANCELATION"),
        this.$t("APPLICATION.ADDONS_TYPE.ASSISTANCE"),
        this.$t("APPLICATION.ADDONS_TYPE.TICKET"),
        this.$t("APPLICATION.ADDONS_TYPE.DISCOUNT"),
        this.$t("APPLICATION.ADDONS_TYPE.CUPON"),
      ];

      return labels[id];
    },
  },
  computed: {
    ...mapGetters(["application"]),
  },
};
</script>

<style lang="sass" scoped></style>
