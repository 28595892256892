<template>
  <div>
    <v-card>
      <v-card-title>{{ $t("APPLICATION.STUDENT.TITLE") }}</v-card-title>
      <v-card-text>
        <v-row>
          <!-- FIRST NAME -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.FNAME") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ info.firstname }}
          </b-col>

          <!-- LAST NAME -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.LNAME") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ info.lastname }}
          </b-col>

          <!-- CITY -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.CITY") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ info.city }}
          </b-col>

          <!--STATE -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.STATE") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ info.state }}
          </b-col>

          <!--PHONE -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.PHONE") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ info.phone }}
          </b-col>

          <!-- EMAIL -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.EMAIL") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ info.email }}
          </b-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title v-if="InvoiceData">{{
        $t("APPLICATION.STUDENT.BILLING")
      }}</v-card-title>
      <v-card-title v-else>
        <div>
          {{ $t("APPLICATION.STUDENT.BILLING") }}
        </div>
        <v-row align="center" justify="end" class="mr-3">
          <a class="body-2" v-if="help === false" @click="help = true">
            {{ $t("GENERAL.SHOW_HELP") }}
          </a>
          <a class="body-2" v-else @click="help = false">
            {{ $t("GENERAL.HIDE_HELP") }}
          </a>
        </v-row>
      </v-card-title>
      <v-card-text v-if="InvoiceData">
        <v-row>
          <!-- FIRST NAME -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.FNAME") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ InvoiceData.firstname }}
          </b-col>

          <!-- LAST NAME -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.LNAME") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ InvoiceData.lastname }}
          </b-col>

          <!-- CITY -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.CITY") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ InvoiceData.city }}
          </b-col>

          <!--STATE -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.STATE") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ InvoiceData.state }}
          </b-col>

          <!--PHONE -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.PHONE") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ InvoiceData.phone }}
          </b-col>

          <!-- EMAIL -->
          <b-col lg="6" md="6" sm="6" cols="6">
            <strong class="d-block font-size-1rem">
              {{ $t("APPLICATION.STUDENT.EMAIL") }}
            </strong>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="6">
            {{ InvoiceData.email }}
          </b-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-alert
          v-model="help"
          type="info"
          border="left"
          elevation="2"
          colored-border
        >
          {{ $t("APPLICATION.STUDENT.INFO") }}
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      help: true,
      form: {
        firstname: null,
        lastname: null,
        city: null,
        state: null,
        phone: null,
        email: null,
        created_at: null,
      },
    };
  },
  props: {
    InvoiceData: Object,
  },
  created() {
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      const { ApplicationStudentData } = this.application;
      if (ApplicationStudentData) {
        this.form = ApplicationStudentData;
      }
    },
  },
  computed: {
    ...mapGetters(["campus", "application"]),
    info() {
      return this.form;
    },
    age() {
      const currentDate = new Date();
      const initialDate = new Date(this.form.created_at);
      const auxAge =
        Number(currentDate.getFullYear()) - Number(initialDate.getFullYear());
      return this.form.created_at !== null ? auxAge : null;
    },
  },
  watch: {
    application: function(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.loadInfo();
      }
    },
  },
};
</script>

<style scoped>
.font-size-1rem {
  font-size: 1.1rem;
}
</style>
