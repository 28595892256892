<template>
  <v-row :class="{ 'flex-row-reverse': data.student }">
    <!-- MESSAGE -->
    <b-col lg="10" md="10" sm="10" cols="10">
      <div class="bubble-chat" :class="{ 'bubble-chat--green': greenMessage }">
        <div>
          <small>
            <b>
              {{ data.first_name }}
              {{ data.last_name }} -
              {{ data.hour }}
            </b>
          </small>
        </div>
        {{ data.message }}
      </div>
    </b-col>
    <!-- ICON -->
    <b-col lg="2" md="2" sm="2" cols="2">
      <div class="avatar-chat">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-avatar color="primary" size="56" v-if="data.image" v-on="on">
              <img :src="`${data.image.url}?h=100&w=100&fit=crop`" />
            </v-avatar>
            <v-avatar color="primary" size="56" v-else v-on="on">
              {{ data.initials }}
            </v-avatar>
          </template>
          <span>
            {{ data.first_name }}
            {{ data.last_name }}
            <br />
            {{ data.position }}
          </span>
        </v-tooltip>
      </div>
    </b-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: Object,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    greenMessage() {
      return this.currentUser.id === this.data.userId;
    },
  },
};
</script>

<style scoped>
.bubble-chat {
  background-color: white;
  border-radius: 20px;
  color: black;
  min-height: 60px;
  padding: 12px;
  word-break: break-all;
  font-size: 1.1rem;
}

.bubble-chat--green {
  background-color: #dcf8c6;
}

.avatar-chat {
  width: 100%;
  height: 100%;
  position: relative;
}

.v-avatar {
  height: 40px !important;
  min-width: unset !important;
  width: 40px !important;
  color: white;
  position: absolute;
  bottom: 0;
}
</style>
