<template>
  <div>
    <v-file-input
      v-model="file"
      id="document_file"
      @change="uploadFile"
      class="d-none"
    />
    <v-stepper vertical>
      <!-- TITLE -->
      <div class="v-card__title text-color--black">
        {{ $t("APPLICATION.DOCUMENTS.TITLE") }}
      </div>

      <!-- STEP 1 -->
      <v-stepper-step
        editable
        step="1"
        :complete="file_agrrements"
        editIcon="check"
      >
        {{ $t("APPLICATION.DOCUMENTS.OPT1") }}
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-btn
          depressed
          color="primary"
          @click="uploadFileClick(AGRREMENTS)"
          :loading="loading_file"
          :disabled="file_agrrements"
        >
          <font-awesome-icon icon="upload" class="mr-1" />
          {{ $t("APPLICATION.UPLOAD") }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="ml-3"
          :disabled="!file_agrrements"
          :href="file_agrrements"
          target="_blank"
        >
          {{ $t("APPLICATION.VIEW") }}
        </v-btn>
      </v-stepper-content>

      <!-- STEP 2 -->
      <v-stepper-step
        editable
        step="2"
        editIcon="check"
        :complete="file_lodgings"
        v-if="applyLodging"
      >
        {{ $t("APPLICATION.DOCUMENTS.OPT2") }}
      </v-stepper-step>
      <v-stepper-content step="2" v-if="applyLodging">
        <v-btn
          depressed
          color="primary"
          @click="uploadFileClick(LODGING)"
          :loading="loading_file"
          :disabled="file_lodgings"
        >
          <font-awesome-icon icon="upload" class="mr-1" />
          {{ $t("APPLICATION.UPLOAD") }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="ml-3"
          :disabled="!file_lodgings"
          :href="file_lodgings"
          target="_blank"
        >
          {{ $t("APPLICATION.VIEW") }}
        </v-btn>
      </v-stepper-content>

      <!-- STEP 3 -->
      <v-stepper-step
        editable
        :step="applyLodging ? STEP_3 : STEP_2"
        editIcon="check"
        :complete="file_transfer"
        v-if="applyTransfer"
      >
        {{ $t("APPLICATION.DOCUMENTS.OPT3") }}
      </v-stepper-step>
      <v-stepper-content
        :step="applyLodging ? STEP_3 : STEP_2"
        v-if="applyTransfer"
      >
        <v-btn
          depressed
          color="primary"
          @click="uploadFileClick(TRANSFER)"
          :loading="loading_file"
          :disabled="file_transfer"
        >
          <font-awesome-icon icon="upload" class="mr-1" />
          {{ $t("APPLICATION.UPLOAD") }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          :disabled="!file_transfer"
          class="ml-3"
          :href="file_transfer"
          target="_blank"
        >
          {{ $t("APPLICATION.VIEW") }}
        </v-btn>
      </v-stepper-content>

      <!-- STEP 4 -->
      <v-stepper-step
        editable
        :step="stepFourOrTwo"
        editIcon="check"
        :complete="file_invoice"
      >
        {{ $t("APPLICATION.DOCUMENTS.OPT4") }}
      </v-stepper-step>
      <v-stepper-content :step="stepFourOrTwo">
        <v-btn
          depressed
          color="primary"
          @click="uploadFileClick(INVOICE)"
          :loading="loading_file"
          :disabled="file_invoice"
        >
          <font-awesome-icon icon="upload" class="mr-1" />
          {{ $t("APPLICATION.UPLOAD") }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          :disabled="!file_invoice"
          class="ml-3"
          :href="file_invoice"
          target="_blank"
        >
          {{ $t("APPLICATION.VIEW") }}
        </v-btn>
      </v-stepper-content>

      <!-- STEP 5 -->
      <v-stepper-step
        editable
        :step="stepFourOrTwo + 1"
        editIcon="check"
        v-if="applyCourse"
        :complete="file_letter_acceptance"
      >
        {{ $t("APPLICATION.DOCUMENTS.OPT5") }}
      </v-stepper-step>
      <v-stepper-content :step="stepFourOrTwo + 1">
        <v-btn
          depressed
          color="primary"
          @click="uploadFileClick(LETTER_ACCEPTANCE)"
          :loading="loading_file"
          :disabled="file_letter_acceptance"
        >
          <font-awesome-icon icon="upload" class="mr-1" />
          {{ $t("APPLICATION.UPLOAD") }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          :disabled="!file_letter_acceptance"
          class="ml-3"
          :href="file_letter_acceptance"
          target="_blank"
        >
          {{ $t("APPLICATION.VIEW") }}
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPLOAD_FILE } from "@/core/services/store/application/application.module";
export default {
  props: {
    productTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    productService: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      e6: 1,
      STEP_2: 2,
      STEP_3: 3,
      AGRREMENTS: 1,
      LODGING: 2,
      TRANSFER: 3,
      INVOICE: 4,
      LETTER_ACCEPTANCE: 5,
      file: null,
      type: null,
      loading_file: false,
      file_agrrements: null,
      file_lodgings: null,
      file_transfer: null,
      file_invoice: null,
      file_letter_acceptance: null,
      applyLodging: false,
      applyTransfer: false,
      applyCourse: false,
      LODGING_TYPE: "ROOM",
      PRODUCT_SERVICE_TYPE: "SERVICE",
      COURSE_TYPE: "COURSE",
      PRODUCT_SERVICE_TYPE_TRANSFERS: 1,
    };
  },
  mounted() {
    this.verifyApplyLodging();
  },
  methods: {
    /**
     * get new items
     * @method uploadFileClick
     */
    uploadFileClick(type) {
      const input = document.getElementById("document_file");
      this.type = type;
      input.click();
    },

    /**
     * get new items
     * @method typeFile
     */
    typeFile(type, url) {
      switch (type) {
        case this.AGRREMENTS:
          this.file_agrrements = url;
          break;
        case this.LODGING:
          this.file_lodgings = url;
          break;
        case this.TRANSFER:
          this.file_transfer = url;
          break;
        case this.INVOICE:
          this.file_invoice = url;
          break;
        case this.LETTER_ACCEPTANCE:
          this.file_letter_acceptance = url;
          break;
      }
    },

    /**
     * get new items
     * @method uploadFile
     */
    uploadFile() {
      this.loading_file = true;

      let campusId = null;
      let applicationProductId = null;

      if (this.getCampus && this.getCampus.campusId) {
        campusId = this.getCampus.campusId;
        const applicationProducts = this.application.ApplicationProducts?.filter(
          item => item.campusId === campusId
        );
        applicationProductId =
          applicationProducts.length > 0
            ? applicationProducts[0].applicationProductId
            : this.application.ApplicationProducts[0].applicationProductId;
      } else {
        campusId = this.application.ApplicationProducts[0].campusId;
        applicationProductId = this.application.ApplicationProducts[0]
          .applicationProductId;
      }

      let formData = new FormData();
      formData.append("files", this.file);
      formData.append("documentTypeId", this.type);
      formData.append("campusId", campusId);
      formData.append("applicationProductId", applicationProductId);
      formData.append("from", this.currentUser.name);
      formData.append("studentId", this.application.studentId);

      this.$store
        .dispatch(UPLOAD_FILE, formData)
        .then(data => {
          if (data.ERROR) {
            this.loading_file = false;
            this.$bvToast.toast(this.$t("MESSAGE.ERROR.FILE_NEW"), {
              title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
              variant: "danger",
              solid: true,
            });
          } else {
            const { documentTypeId, File } = data[data.length - 1];

            this.typeFile(documentTypeId, File.url);

            this.$bvToast.toast(this.$t("GENERAL.SAVE_FILE"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            this.loading_file = false;
            this.file = null;
          }
        })
        .catch(() => {
          this.loading_file = false;
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.FILE_NEW"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
        });
    },

    /**
     * verify apply lodging
     * @method verifyApplyLodging
     */
    async verifyApplyLodging() {
      const result = await this.productTypes?.filter(item => {
        if (item === this.PRODUCT_SERVICE_TYPE) {
          this.verifyApplyTransfer();
        }
        return item === this.LODGING_TYPE;
      });

      this.applyLodging = result.length > 0;
      await this.verifyFilesUploaded();
    },

    /**
     * verify apply lodging
     * @method verifyApplyTransfer
     */
    async verifyApplyTransfer() {
      this.applyTransfer =
        this.productService &&
        this.productService[0].ApplicationProductService.serviceTypeId ===
          this.PRODUCT_SERVICE_TYPE_TRANSFERS;
    },

    /**
     * verify apply course
     * @method verifyApplyCourse
     */
    async verifyApplyCourse() {
      const result = await this.productTypes?.filter(item => {
        return item === this.COURSE_TYPE;
      });

      this.applyCourse = result.length > 0;
    },

    async verifyFilesUploaded() {
      const { ApplicationDocument } = this.application;
      if (ApplicationDocument) {
        ApplicationDocument.forEach(element => {
          const { File } = element;
          const { typeId } = File;
          this.typeFile(typeId, File.url);
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getCampus", "application", "currentUser"]),
    stepFourOrTwo() {
      let step = 2;
      if (this.applyLodging) {
        step++;
      }

      if (this.applyTransfer) {
        step++;
      }

      return step;
    },
  },
  watch: {
    productService: function() {
      this.verifyApplyLodging();
    },
    productTypes: function() {
      this.verifyApplyLodging();
      this.verifyApplyCourse();
    },
  },
};
</script>

<style scoped>
.text-color--black {
  color: rgba(0, 0, 0, 0.87) !important;
}

.primary {
  background-color: #1867c0 !important;
  border-color: #1867c0 !important;
}

.v-stepper__step__step {
  color: white !important;
  margin-right: 10px !important;
}

.v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.v-stepper__content {
  margin: -8px -36px -16px 36px;
  padding: 16px 60px 16px 23px;
  width: auto;
}

.v-icon {
  color: white !important;
}
</style>
