<template>
  <div v-if="application">
    <v-row>
      <b-col lg="12" md="12" sm="12">
        <strong> {{ $t("APPLICATION.TITLE_FORM") }}</strong>
        <span class="text-muted"> # {{ application.applicationId }} </span>
        <v-chip
          v-if="status"
          :color="status.color"
          :class="{ 'text-white': status.color }"
          small
        >
          {{ status.text }}
        </v-chip>
        <div class="float-right">
          <strong>
            {{ $t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM6") }} :
          </strong>
          {{ application.created_at | moment(date_format) }}
        </div>
      </b-col>
      <b-col lg="8" md="8" sm="12">
        <v-row gutters="2" class="pt-0 mt-0 pb-0 mb-0">
          <!-- CARD INFORMATION -->
          <b-col lg="6" md="6" sm="12">
            <Student :InvoiceData="invoiceData" :dateFormat="date_format" />
            <Course
              class="mt-5"
              :data="courseInfo[0]"
              :updateStatus="changeStatus"
              v-if="courseInfo"
            />
            <Lodging
              class="mt-5"
              :data="lodgingInfo[0]"
              :updateStatus="changeStatus"
              v-if="lodgingInfo"
            />
          </b-col>
          <!-- LODGING -->
          <b-col lg="6" md="6" sm="12">
            <Documents
              :productTypes="listProductTypes"
              :productService="serviceInfo"
            />
            <FileSharing
              class="mt-5"
              :documentData="application.ApplicationDocument"
            />
          </b-col>
        </v-row>
      </b-col>
      <b-col lg="4" md="4" sm="12">
        <v-row gutters="2" class="pt-0 mt-0 pb-0 mb-0">
          <!-- CHAT -->
          <b-col lg="12" md="12" sm="12">
            <Chat :campusId="campusId" />
          </b-col>
          <!-- ACTIVITY -->
          <b-col lg="12" md="12" sm="12">
            <ActivityLog
              :data="logInfo"
              :date_format="date_format"
              class="mt-5"
            />
          </b-col>
        </v-row>
      </b-col>
      <!-- PAYMENT INFORMATION -->
      <b-col
        lg="8"
        md="8"
        sm="12"
        class="mt-5"
        v-if="superAdmin && ApplicationPayments"
      >
        <Payment />
      </b-col>
      <!-- ADDONS INFORMATION -->
      <b-col
        lg="4"
        md="4"
        sm="12"
        class="mt-5"
        v-if="superAdmin && applicationAddons"
      >
        <Addons />
      </b-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_APPLICATION,
  GET_MESSAGES,
  RESET_MESSAGES,
  UPDATE_STATUS,
} from "@/core/services/store/application/application.module";
import Documents from "./components/Documents";
import Student from "./components/Student";
import Chat from "./components/Chat";
import Course from "./components/Course";
import Lodging from "./components/Lodging";
import FileSharing from "./components/FileSharing";
import ActivityLog from "./components/ActivityLog";
import Payment from "./components/Payment";
import Addons from "./components/Addons";
export default {
  components: {
    Student,
    Documents,
    Chat,
    Course,
    Lodging,
    FileSharing,
    ActivityLog,
    Payment,
    Addons,
  },
  data() {
    return {
      invoiceData: null,
      courseInfo: null,
      lodgingInfo: null,
      serviceInfo: null,
      packageInfo: null,
      logInfo: null,
      status: null,
      SUPERADMIN: "1",
      listProductTypes: [],
      campusId: null,
      date_format: null,
      PENDING: 1,
      ACCEPTED: 2,
      DECLINED: 3,
      CANCELED: 4,
    };
  },
  mounted() {
    if (this.currentUser.roleId !== this.SUPERADMIN) {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("APPLICATION.TITLE"),
          route: "/applications",
        },
        { title: this.$t("APPLICATION.INFORMATION") },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("APPLICATION.TITLE"),
          route: "/admin/application",
        },
        { title: this.$t("APPLICATION.INFORMATION") },
      ]);
    }
  },
  async created() {
    const { id } = this.$route.params;
    await this.$store.dispatch(GET_APPLICATION, id);
    const { ApplicationProducts } = this.application;
    const { Campus } = ApplicationProducts[0];
    this.campusId = Campus.campusId;
    this.date_format =
      Campus.date_format_id === 1 ? "DD/MM/YYYY" : "MM/DD/YYYY";
    const query = `campusId=${this.campusId}&applicationId=${id}`;
    this.$store.dispatch(RESET_MESSAGES);
    this.$store.dispatch(GET_MESSAGES, query);
    let auxGeneralStatus = [];
    this.invoiceData = this.application.InvoiceData;

    // CREATE STATUS BY PRODUCTS
    const newData = [];
    if (this.company?.companyId) {
      let campusesFilter = [];
      this.campuses?.forEach(element => {
        campusesFilter.push(element.campusId);
      });

      const applicationsProductsFiltered = ApplicationProducts?.filter(item => {
        if (this.company) {
          return campusesFilter.indexOf(item.campusId) !== -1;
        } else {
          return true;
        }
      });

      applicationsProductsFiltered?.forEach(element => {
        this.listProductTypes.push(element.Product.product_type.code);
        const { applicationStatusId } = element.ApplicationStatus;
        let item = {
          ...element,
          status: this.getStatus(applicationStatusId),
        };
        newData.push(item);
        auxGeneralStatus.push(applicationStatusId);
      });
    } else {
      ApplicationProducts?.forEach(element => {
        this.listProductTypes.push(element.Product.product_type.code);
        const { applicationStatusId } = element.ApplicationStatus;
        let item = {
          ...element,
          status: this.getStatus(applicationStatusId),
        };
        newData.push(item);
        auxGeneralStatus.push(applicationStatusId);
      });
    }

    if (auxGeneralStatus.length === 1) {
      this.status = this.getStatus(auxGeneralStatus[0]);
    } else {
      let generalStatus = 0;
      let auxAccepted = 0;
      auxGeneralStatus.forEach(status => {
        if (status === this.DECLINED) {
          generalStatus = this.DECLINED;
        } else if (status === this.CANCELED) {
          generalStatus = this.CANCELED;
        } else if (status === this.PENDING) {
          generalStatus = this.PENDING;
        } else if (status === this.ACCEPTED) {
          auxAccepted += 1;
        }
      });

      generalStatus =
        auxGeneralStatus.length === auxAccepted ? this.ACCEPTED : generalStatus;
      this.status = this.getStatus(generalStatus);
    }

    // SET DATA BY PRODUCT
    this.courseInfo = newData.filter(
      element => element.Product.product_type.code === "COURSE"
    );
    this.lodgingInfo = newData.filter(
      element => element.Product.product_type.code === "ROOM"
    );
    this.serviceInfo = newData.filter(
      element => element.Product.product_type.code === "SERVICE"
    );
    this.packageInfo = newData.filter(
      element => element.Product.product_type.code === "PACKAGE"
    );

    this.logInfo = this.application.ApplicationLogs;

    if (this.courseInfo.length <= 0) {
      this.courseInfo = null;
    }
    if (this.lodgingInfo.length <= 0) {
      this.lodgingInfo = null;
    }
    if (this.serviceInfo.length <= 0) {
      this.serviceInfo = null;
    }
  },

  methods: {
    getStatus(status) {
      let statusInfo;
      switch (status) {
        case 1:
          statusInfo = {
            color: null,
            text: this.$t("APPLICATION.STATUS.ITEM1"),
            code: status,
          };
          break;
        case 2:
          statusInfo = {
            color: "green",
            text: this.$t("APPLICATION.STATUS.ITEM2"),
            code: status,
          };
          break;
        case 3:
          statusInfo = {
            color: "red",
            text: this.$t("APPLICATION.STATUS.ITEM3"),
            code: status,
          };
          break;
        case 4:
          statusInfo = {
            color: "orange",
            text: this.$t("APPLICATION.STATUS.ITEM4"),
            code: status,
          };
          break;

        default:
          statusInfo = {
            color: "blue",
            text: this.$t("APPLICATION.STATUS.ITEM1"),
            code: status,
          };
          break;
      }

      return statusInfo;
    },
    changeStatus(status, applicationProductId, type) {
      const json = {
        applicationProductId: applicationProductId,
        applicationStatusId: status,
        id: this.application.applicationId,
        from: this.currentUser.name,
        studentId: this.application.studentId,
      };
      this.$store.dispatch(UPDATE_STATUS, json).then(({ data }) => {
        if (type === "course") {
          this.courseInfo[0].status = this.getStatus(data.applicationStatusId);
          this.courseInfo[0].applicationStatusId = data.applicationStatusId;
        } else {
          this.lodgingInfo[0].status = this.getStatus(data.applicationStatusId);
          this.lodgingInfo[0].applicationStatusId = data.applicationStatusId;
        }
        let auxGeneralStatus = [];
        //Updated General Status
        if (this.courseInfo) {
          auxGeneralStatus.push(this.courseInfo[0].applicationStatusId);
        }

        if (this.lodgingInfo) {
          auxGeneralStatus.push(this.lodgingInfo[0].applicationStatusId);
        }

        if (auxGeneralStatus.length === 1) {
          this.status = this.getStatus(auxGeneralStatus[0]);
        } else {
          let generalStatus = 0;
          let auxAccepted = 0;
          auxGeneralStatus.some(status => {
            if (status === this.DECLINED) {
              generalStatus = this.DECLINED;
            } else if (status === this.CANCELED) {
              generalStatus = this.CANCELED;
            } else if (status === this.PENDING) {
              generalStatus = this.PENDING;
            } else if (status === this.ACCEPTED) {
              auxAccepted += 1;
            }
            return generalStatus > 0;
          });

          generalStatus =
            auxGeneralStatus.length === auxAccepted
              ? this.ACCEPTED
              : generalStatus;
          this.status = this.getStatus(generalStatus);
        }

        this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
      });
    },
  },
  computed: {
    ...mapGetters(["application", "currentUser", "company", "campuses"]),
    superAdmin() {
      const { roleId } = this.currentUser;
      return roleId === this.SUPERADMIN;
    },
    applicationAddons() {
      return this.application.ApplicationAddons?.length > 0;
    },
    ApplicationPayments() {
      return this.application.ApplicationPayments?.length > 0;
    },
  },
};
</script>
