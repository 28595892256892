<template>
  <v-card>
    <v-card-title>
      {{ $t("APPLICATION.ACTIVITY.TITLE") }}
    </v-card-title>
    <v-card-text>
      <div v-for="(log, i) in data" :key="i">
        <strong>
          {{ log.text }}
        </strong>
        <span class="text-muted float-right">
          {{ log.created_at | moment(date_format) }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    data: Object,
    date_format: String,
  },
};
</script>

<style scoped></style>
