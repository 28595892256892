<template>
  <div>
    <div class="content-header-chat">
      <h4 class="text-white m-0 flex-grow-1 mr-3">
        {{ $t("APPLICATION.CHAT.TITLE") }}
      </h4>
    </div>
    <v-card>
      <v-card-text class="v-card-text-chat" ref="scroll">
        <div v-for="(data, i) in messages" :key="i">
          <DividerChat :date="data[0].date" />
          <div v-for="(info, j) in data" :key="j">
            <MessageChat :data="info.data" />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row class="p-0 m-0">
          <b-col lg="11" md="11" sm="11" cols="11" class="mb-0">
            <v-textarea
              :label="$t('APPLICATION.CHAT.PLACEHOLDER')"
              v-model="message"
              required
              auto-grow
              outlined
              dense
              rows="1"
              row-height="10"
              hide-details
              ref="message"
            >
              <template v-slot:append-outer>
                <span class="btn-send">
                  <font-awesome-icon icon="paper-plane" @click="sendMessage" />
                </span>
              </template>
            </v-textarea>
          </b-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  STORE_MESSAGES,
  GET_MESSAGES,
} from "@/core/services/store/application/application.module";
import DividerChat from "./DividerChat";
import MessageChat from "./MessageChat";

export default {
  components: {
    DividerChat,
    MessageChat,
  },
  props: {
    campusId: Number,
  },
  data() {
    return {
      message: null,
      offsetTop: 9999,
    };
  },
  updated() {
    this.scrollChat();
  },
  methods: {
    sendMessage() {
      const json = {
        applicationId: this.application.applicationId,
        campusId: this.campusId,
        userId: this.currentUser.user.id,
        message: this.message,
        from: this.currentUser.name,
        studentIdEmail: this.application.studentId,
      };

      this.$store.dispatch(STORE_MESSAGES, json).then(() => {
        this.$refs.message.reset();
        const { id } = this.$route.params;
        const query = `campusId=${this.campusId}&applicationId=${id}`;
        this.$store.dispatch(GET_MESSAGES, query);
      });
    },

    scrollChat() {
      const scroll = this.$refs.scroll;
      scroll.scrollTop = scroll.scrollHeight;
    },
  },
  computed: {
    ...mapGetters(["application", "messages", "currentUser"]),
    backgroundImage() {
      return `${process.env.VUE_APP_CDN_PATH}images/background/quick_start.jpg`;
    },
  },
};
</script>

<style scoped>
.content-header-chat {
  background-color: #5867dd;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.v-card-text-chat {
  background-color: #eae8e8;
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.v-card-text-chat::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.v-card-text-chat::-webkit-scrollbar-track {
  border-radius: 10px;
}

.v-card-text-chat::-webkit-scrollbar {
  -webkit-appearance: none;
}

.v-card-text-chat::-webkit-scrollbar:vertical {
  width: 8px;
}

.v-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.v-text-field__details {
  display: none;
}

.btn-send {
  background-color: #5867dd;
  color: white;
  padding: 8px;
  border-radius: 50px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}
</style>
